
import { Vue } from 'vue-class-component'
import { ReservationModel } from '@/models/ReservationModel'
import httpClient from '@/services/httpClient'
import firebase from 'firebase/app'
import User = firebase.User;

export default class BookingsList extends Vue {
  user: User | null = null

  reservations: ReservationModel[] = []

  created (): void {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user
    })
  }

  async mounted () {
    const fetchReservations = async () => {
      try {
        const reservationsResponse = await httpClient.get('/v1/reservations')
        this.reservations = reservationsResponse.data as ReservationModel[]
      } catch (err) {
        console.log(err)
      }
    }

    await fetchReservations()
  }

  toDate (epochMillis: number): string {
    const d = new Date(0)
    d.setMilliseconds(epochMillis)
    return d.toLocaleDateString()
  }

  toTime (epochMillis: number): string {
    const d = new Date(0)
    d.setMilliseconds(epochMillis)
    return d.toLocaleTimeString()
  }
}
