<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/home/Header.vue'
import Footer from '@/components/home/Footer.vue'

@Options({
  components: {
    Footer,
    Header
  }
})
export default class App extends Vue {
}
</script>

<style>
  @import "assets/css/style.css";
</style>
