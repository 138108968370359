
import { Options, Vue } from 'vue-class-component'
import PlacesTitle from '@/components/place/list/PlacesTitle.vue'
import PlaceModel from '@/models/PlaceModel'
import axios from 'axios'
import PlacesItem from '@/components/place/list/PlacesItem.vue'
import { useStore } from '@/store'

@Options({
  components: {
    PlacesItem,
    PlacesTitle
  }
})
export default class Places extends Vue {
  places: PlaceModel[] = []
  store = useStore()

  mounted () {
    this.places = this.store.state.placesSearchResult
  }
}
