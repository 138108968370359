export class AddressModel {
    addressLine1: string
    addressLine2: string
    postCode: string
    city: string
    country: string

    constructor () {
      this.addressLine1 = ''
      this.addressLine2 = ''
      this.postCode = ''
      this.city = ''
      this.country = ''
    }
}

export default AddressModel
