import axios from 'axios'
import firebase from 'firebase/app'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
})

httpClient.interceptors.request.use(async config => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const token = await firebase.auth().currentUser.getIdToken()
  config.headers.token = token
  config.headers.Authorization = token
  return config
},
(error) => {
  return Promise.reject(error)
})

export default httpClient
