
import { Options, Vue } from 'vue-class-component'
import PlaceModel from '@/models/PlaceModel'

@Options({
  props: {
    place: PlaceModel
  }
})
export default class PlacesItem extends Vue {
  place!: PlaceModel
}
