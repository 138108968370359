
import 'vue3-carousel/dist/carousel.css'
import { Options, Vue } from 'vue-class-component'
import { GoogleMap, Marker } from 'vue3-google-map'
import axios from 'axios'
import Booking from '@/components/place/display/Booking.vue'
import OpeningHours from '@/components/place/display/OpeningHours.vue'
import PlaceContact from '@/components/place/display/PlaceContact.vue'
import ShareLike from '@/components/place/display/ShareLike.vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

  @Options({
    components: {
      Booking,
      Carousel,
      GoogleMap,
      Marker,
      OpeningHours,
      PlaceContact,
      ShareLike,
      Slide,
      Pagination,
      Navigation
    }
  })
export default class Place extends Vue {
  center = {
    lat: 49.6027415,
    lng: 6.1261184
  }

  place = {
    name: '',
    slug: '',
    email: '',
    phoneNumber: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: ''
    },
    openingHour: null,
    closingHour: null,
    image: {
      id: '',
      url: ''
    }
  }

  mounted () {
    const populatePlace = async () => {
      try {
        const http = axios.create({
          baseURL: process.env.VUE_APP_BACKEND_URL
        })
        const placeData = await http.get('/v1/places/' + this.$route.params.placeSlug)
        this.place = placeData.data
      } catch (err) {
        console.log(err)
      }
    }
    populatePlace()
  }
}
