
import { Options, Vue } from 'vue-class-component'

@Options({
  emits: [
    'update:openingHour',
    'update:closingHour'
  ],
  props: {
    day: String,
    openingHour: Number,
    closingHour: Number
  }
})
export default class OpeningHoursSelect extends Vue {
  day!: string
  openingHour!: number
  closingHour!: number

  hours (): number[] {
    const result = []
    for (let i = 1; i <= 24; i++) {
      result.push(i)
    }
    return result
  }
}
