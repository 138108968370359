
import { Options, Vue } from 'vue-class-component'
import httpClient from '@/services/httpClient'
import firebase from 'firebase/app'
import User = firebase.User;
import OpeningHoursSelect from '@/components/place/add/OpeningHoursSelect.vue'
import Multiselect from '@vueform/multiselect'
import axios from 'axios'

@Options({
  components: {
    Multiselect,
    OpeningHoursSelect
  }
})
export default class AddPlace extends Vue {
  handyAttachments = []
  user: User | null = null

  availableTags = []

  newPlace = {
    name: '',
    description: '',
    email: '',
    phoneNumber: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: '',
      country: 'Luxembourg'
    },
    openingHour: null,
    closingHour: null,
    timeslotDurationSeconds: null,
    timeslotDefaultSeatsInside: 0,
    timeslotDefaultSeatsOutside: 0,
    ownerUid: '',
    tagIds: [],
    images: ['', undefined]
  }

  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user
    })
  }

  async mounted () {
    const fetchTags = async () => {
      try {
        const http = axios.create({
          baseURL: process.env.VUE_APP_BACKEND_URL
        })
        const tagsResponse = await http.get('/v1/tags')
        this.availableTags = tagsResponse.data.map((tag: any) => ({
          value: tag.id,
          label: tag.name
        }))
      } catch (err) {
        console.log(err)
      }
    }
    await fetchTags()
  }

  addPlace (): void {
    if (this.user && this.user.uid) {
      this.newPlace.ownerUid = this.user.uid
      httpClient
        .post('v1/places', this.newPlace)
        .then(() => this.$router.push('/'))
    } else {
      console.log('You need to be logged in as place owner')
    }
  }

  hours (): number[] {
    const result = []
    for (let i = 1; i <= 24; i++) {
      result.push(i)
    }
    return result
  }

  onFileChange (e: any): void {
    var files = e.target.files || e.dataTransfer.files
    if (!files.length) { return }
    files.forEach((element: Blob) => {
      this.newPlace.images = []
      this.createImage(element)
    })
  }

  createImage (file: Blob): void {
    var reader = new FileReader()

    reader.onload = (e) => {
      this.newPlace.images.push(e.target?.result?.toString())
    }
    reader.readAsDataURL(file)
  }

  updateOpeningHour (e: any) {
    console.log(e.target.value)
  }

  updateClosingHour (e: any) {
    console.log(e.target.value)
  }
}
