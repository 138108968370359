import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import AddPlace from '@/components/place/add/AddPlace.vue'
import FirebaseAuth from '@/components/FirebaseAuth.vue'
import Profile from '@/components/user/Profile.vue'
import Place from '@/components/place/display/Place.vue'
import BookingsList from '@/components/user/BookingsList.vue'
import Places from '@/components/place/list/Places.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard/bookings',
    name: 'Dashboard',
    component: BookingsList
  },
  {
    path: '/login',
    name: 'Sign in',
    component: FirebaseAuth
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/add-place',
    name: 'Add place',
    component: AddPlace
  },
  {
    path: '/places',
    name: 'Places',
    component: Places
  },
  {
    path: '/places/:placeSlug',
    name: 'Place',
    component: Place
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

export default router
