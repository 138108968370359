
import { Vue } from 'vue-class-component'
import firebase from 'firebase/app'
import User = firebase.User;

export default class Profile extends Vue {
  user: User | null = null

  created () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user
      }
    })
  }
}
