import AddressModel from '@/models/AddressModel'
import TimeslotModel from '@/models/TimeslotModel'
import ImageModel from '@/models/ImageModel'

export class PlaceModel {
    id: string
    name: string
    slug: string
    email: string
    phoneNumber: string
    address: AddressModel
    timeslots: TimeslotModel[]
    images: ImageModel[]

    constructor () {
      this.id = ''
      this.name = ''
      this.slug = ''
      this.email = ''
      this.phoneNumber = ''
      this.address = new AddressModel()
      this.timeslots = []
      this.images = []
    }
}

export default PlaceModel
