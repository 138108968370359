
import { Options, Vue } from 'vue-class-component'
import { Calendar, DatePicker } from 'v-calendar'
import axios from 'axios'
import { useStore } from '@/store'

  @Options({
    components: {
      Calendar, DatePicker
    }
  })
export default class SearchBar extends Vue {
    guests = 0
    minDate = this.now()
    searchDateStart = this.now()
    searchDateEnd = this.now()
    searchCity = 'Luxembourg'
    store = useStore()

    decGuests (): void {
      if (this.guests > 0) {
        this.guests--
      }
    }

    incGuests (): void {
      this.guests++
    }

    now (): Date {
      const nearest15Minutes: Date = new Date()
      nearest15Minutes.setMinutes(Math.round(nearest15Minutes.getMinutes() / 15) * 15)
      return nearest15Minutes
    }

    searchPlaces (): void {
      const http = axios.create({
        baseURL: process.env.VUE_APP_BACKEND_URL
      })
      const searchRequest = {
        timeStart: this.searchDateStart.getTime(),
        timeEnd: this.searchDateStart.getTime(),
        seats: this.guests,
        city: this.searchCity,
        tags: []
      }
      http.post('/v1/placeSearchRequests', searchRequest).then(value => {
        this.store.commit('setPlacesSearchResult', value.data)
        this.$router.push('/places')
      })
    }
}
