
import { Vue } from 'vue-class-component'
import '@/assets/scripts/custom'
import firebase from 'firebase/app'
import User = firebase.User;

export default class Header extends Vue {
  user: User | null = null

  created (): void {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user
    })
  }

  signOut (e: any): void {
    e.stopPropagation()
    firebase.auth().signOut()
    this.$router.push('/')
  }
}
