import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import firebase from 'firebase'
import ClickOutside from '@/directives/ClickOutside'

const firebaseConfig = {
  apiKey: 'AIzaSyB97_3oDk2tjMZDVcxrm-1mH8ixTmEDuUI',
  authDomain: 'meal-lu.firebaseapp.com',
  projectId: 'meal-lu',
  storageBucket: 'meal-lu.appspot.com',
  messagingSenderId: '489574607130',
  appId: '1:489574607130:web:cc84e727780e952aa624a3',
  measurementId: 'G-GSRJVPVDQR'
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

const app = createApp(App)

app
  .directive('click-outside', ClickOutside)
  .use(store, key)
  .use(router)
  .mount('#app')
