
import { Options, Vue } from 'vue-class-component'
import PlaceModel from '@/models/PlaceModel'
import { TimeslotModel } from '@/models/TimeslotModel'
import firebase from 'firebase/app'
import User = firebase.User;
import httpClient from '@/services/httpClient'
import { DatePicker } from 'v-calendar'

@Options({
  components: {
    DatePicker
  },
  props: {
    place: Object
  }
})
export default class Booking extends Vue {
  user: User | null = null

  place!: PlaceModel

  guestsExpanded = false
  timeslotsExpanded = false
  minDate: Date = new Date()
  picked: Date = new Date()
  timeslots: TimeslotModel[] = []
  pickedTimeslot: TimeslotModel | null = null
  guests = 0

  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user
    })
  }

  showTimeslots (): void {
    if (!this.timeslotsExpanded) {
      this.timeslots = []
      this.place.timeslots.forEach((slot) => {
        if (this.isSameDay(slot, this.picked)) {
          this.timeslots.push(slot)
          this.timeslots.sort(function (a, b) {
            return a.startTime - b.startTime
          })
        }
      })
    }

    this.timeslotsExpanded = !this.timeslotsExpanded
  }

  showGuests (): void {
    this.guestsExpanded = !this.guestsExpanded
  }

  closeGuests (): void {
    this.guestsExpanded = false
  }

  closeTimeslots (): void {
    this.timeslotsExpanded = false
  }

  isSameDay (slot: TimeslotModel, date: Date): boolean {
    const slotDate: Date = new Date(slot.startTime)
    return slotDate.getDay() === date.getDay() &&
      slotDate.getMonth() === date.getMonth() &&
      slotDate.getFullYear() === date.getFullYear()
  }

  time (epochMillis: number): string {
    const d = new Date(0)
    d.setMilliseconds(epochMillis)
    return d.toLocaleTimeString()
  }

  decGuests (): void {
    if (this.guests > 0) {
      this.guests--
    }
  }

  incGuests (): void {
    this.guests++
  }

  book (): void {
    const reservationRequest = {
      customerUid: this.user?.uid,
      timeslotId: this.pickedTimeslot?.id,
      seats: this.guests
    }
    httpClient.post('/v1/reservations', reservationRequest)
      .then(() => {
        this.$router.push('/dashboard/bookings')
      }, (err) => {
        console.log(err)
      })
  }

  pickTimeslot (slot: TimeslotModel): void {
    this.pickedTimeslot = slot
    this.timeslotsExpanded = false
  }
}
