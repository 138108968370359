import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9 col-md-8 padding-right-30" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlacesTitle = _resolveComponent("PlacesTitle")
  const _component_PlacesItem = _resolveComponent("PlacesItem")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_PlacesTitle),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(this.places, (place) => {
              return (_openBlock(), _createBlock(_component_PlacesItem, {
                key: place,
                place: place
              }, null, 8, ["place"]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}