import { createStore, Store, useStore as baseUseStore } from 'vuex'
import PlaceModel from '@/models/PlaceModel'
import { InjectionKey } from 'vue'

export interface State {
  placesSearchResult: PlaceModel[]
}

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    placesSearchResult: [] as PlaceModel[]
  },
  mutations: {
    setPlacesSearchResult (state, newPlaces: PlaceModel[]) {
      state.placesSearchResult = newPlaces
    }
  },
  actions: {
  },
  modules: {
  }
})

export function useStore () {
  return baseUseStore(key)
}
