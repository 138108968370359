
import { Options, Vue } from 'vue-class-component'
import PlaceModel from '@/models/PlaceModel'

@Options({
  props: {
    place: Object
  }
})
export default class PlaceContact extends Vue {
    place!: PlaceModel
}
