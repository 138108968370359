
import 'vue3-carousel/dist/carousel.css'
import { Options, Vue } from 'vue-class-component'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import CarouselItem from '@/components/home/CarouselItem.vue'
import axios from 'axios'
import PlaceModel from '@/models/PlaceModel'

  @Options({
    components: {
      Carousel,
      CarouselItem,
      Slide,
      Pagination,
      Navigation
    }
  })
export default class PlacesCarousel extends Vue {
    places: PlaceModel[] = []

    async created () {
      const fetchPlaces = async () => {
        try {
          const http = axios.create({
            baseURL: process.env.VUE_APP_BACKEND_URL
          })
          const placesResponse = await http.get('/v1/places')
          this.places = placesResponse.data as PlaceModel[]
        } catch (err) {
          console.log(err)
        }
      }
      await fetchPlaces()
    }
}
