
import { Options, Vue } from 'vue-class-component'
import PlacesCarousel from '@/components/home/PlacesCarousel.vue'
import SearchBar from '@/components/home/SearchBar.vue'

@Options({
  components: {
    PlacesCarousel,
    SearchBar
  }
})
export default class Home extends Vue {
}
